import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationLink } from '../../interfaces/navigation-item.interface';
import { PopoverService } from '../../components/popover/popover.service';
import { Observable, of } from 'rxjs';
import { UserMenuComponent } from '../../components/user-menu/user-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../../components/search-modal/search-modal.component';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/customer.model';
import { SharedDataService } from 'src/app/services/shared-data/shared-data.service';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));
  userVisible$ = this.configService.config$.pipe(map(config => config.sidenav.user.visible));
  searchVisible$ = this.configService.config$.pipe(map(config => config.sidenav.search.visible));

  userMenuOpen$: Observable<boolean> = of(false);

  items = this.navigationService.items;
  id: string;
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  user: any;
  imageUrl: string = '';
  userName: string;
  userSurname: string;

  constructor(private navigationService: NavigationService,
              private layoutService: LayoutService,
              private configService: ConfigService,
              private readonly popoverService: PopoverService,
              private readonly dialog: MatDialog,
              private userService: UserService,
              private cdr: ChangeDetectorRef,
              private sharedDataService: SharedDataService) { 
                
              }

  ngOnInit() {

    this.getLoggedInUser();
    this.isUserDataChanged();
  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: UserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom'
          }
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: 'vex-dialog-glossy',
      width: '100%',
      maxWidth: '600px'
    });
  }

  getLoggedInUser() {
    const authData: string | null = localStorage.getItem(this.authLocalStorageToken);
    if(authData) {
      const data = JSON.parse(authData);
      this.id = data.id;
    }
    this.userService.view(this.id)
      .subscribe(user => {
        this.user = user;
        if(this.user) {
          if(this.user.logo != '') {
            if(this.user.logo != null) {
              this.imageUrl = `${environment.backendUrl}/uploads/user/${this.user.id}/${this.user.logo}`;
            }
          }
          this.userName = this.user.name;
          this.userSurname = this.user.surname;
          this.cdr.detectChanges();
        } else {
          this.imageUrl = '';
        }

        
      })
  }

  isUserDataChanged() {
    this.sharedDataService.currentLogo.subscribe(newLogo => {
      if(newLogo != '') {
        if(newLogo != null) {
          if(newLogo.includes('data:image')) {
            this.imageUrl = newLogo
          }
          else {
            let url = `${environment.backendUrl}/uploads/user/${this.id}/${newLogo}`;
            this.imageUrl = url;
          }
        }
      }
      else if(this.user) {
        this.imageUrl = '';
      }
    });

    this.sharedDataService.currentName.subscribe(newName => {
      if(newName != '') {
        this.userName = newName;
      }
      else if(this.user){
        this.userName = this.user.name;
      }
    });

    this.sharedDataService.currentSurname.subscribe(newSurname => {
      if(newSurname != '') {
        this.userSurname = newSurname;
      }
      else if(this.user) {
        this.userSurname = this.user.surname;
      }
    });
  }
}
