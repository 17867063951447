<div class="container-main">
    <div class="main">
        <img src="assets/img/icons/logos/sc2-logo-landing.svg" class="big-img" alt="image-1">

        <div class="sm-img-bg one-bg" alt="button-1"></div>
        <button (click)="openLogin()" class="sm-img one" alt="button-1"><p>Login</p></button>

        <div class="sm-img-bg two-bg" alt="button-2"></div>
        <button (click)="openTips()" class="sm-img two" alt="button-2"><p>Tips</p></button>

        <div class="sm-img-bg three-bg" alt="button-3"></div>
        <button class="sm-img three" alt="button-1"><p>Register</p></button>

        <div class="sm-img-bg four-bg" alt="button-4"></div>
        <button class="sm-img four" alt="button-4"><p>Disclaimer</p></button>
    </div>
</div>