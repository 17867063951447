import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor(private router: Router) {
    localStorage.removeItem(this.authLocalStorageToken);
  }

  openLogin() {
    this.router.navigate(['/auth/login']);
  }

  openTips() {
    this.router.navigate(['/hints-tips/carousel']);
  }

}