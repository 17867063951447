import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() customTemplate: TemplateRef<any>;
  currentYear: number;

  constructor() {
    this.currentYear = ((new Date()).getFullYear());
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {}

  openLinkInNewTab() {
    var url = 'https://infordata.it/';
    window.open(url, "_blank");
  }
}
