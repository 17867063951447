export class User {
    id: number;
    logo: string;
    fullName: string;
    name: string;
    surname: string;
    email: string;
    organisation_id: string;
    team_id: string;
  
    constructor(user) {
      this.id = user.id;
      this.logo = user.logo;
      this.fullName = user.name + " " + user.surname
      this.name = user.name;
      this.surname = user.surname;
      this.email = user.email;
      this.organisation_id = user.organisation_id;
      this.team_id = user.team_id;
    }
  
    // get fullName() {
    //   let fullName = '';
  
    //   if (this.name && this.surname) {
    //     fullName = this.name + ' ' + this.surname;
    //   } else if (this.name) {
    //     fullName = this.name;
    //   } else if (this.surname) {
    //     fullName = this.surname;
    //   }
    //   console.log(fullName);
    //   return fullName;
    // }
  
    // set fullName(value) {
    // }
  }
  